<template>
    <div class="full-height-layout fill">
        <landing
            :pages="pages"
        ></landing>
    </div>
</template>

<script>
import Landing from '../../components/Landing'
export default {
    name: 'OrdersLanding',
    components: { Landing },
    data () {
        return {
            pages: [
                {
                    title: 'Orders',
                    description: `View orders for all suppliers`,
                    route: {
                        name: 'OrderListOverview',
                    },
                    actionName: 'SupplierCentral/getAllSupplierTransportSettings',
                    attrs: {
                        'data-test': 'ordersListLink',
                    },
                },
                {
                    title: 'Submissions',
                    description: `View the logs for order submissions.`,
                    route: {
                        name: 'OrderSubmissionsLogsView',
                    },
                    actionName: 'SupplierCentral/getAllOrderSubmissions',
                    attrs: {
                        'data-test': 'submissions-link',
                    },
                },
                {
                    title: 'Transport settings',
                    description: `View and change the transport settings for suppliers`,
                    route: {
                        name: 'OrderSubmissionsTransportSettingsView',
                    },
                    actionName: 'SupplierCentral/getAllSupplierTransportSettings',
                    attrs: {
                        'data-test': 'transport-link',
                    },
                },
            ],
        }
    },
}
</script>
